<template>
  <s-card
    class="stats-card pa-4 pa-lg-6 overflow-hidden"
    style="height: 100%;"
  >
    <div class="d-flex justify-space-between align-center">
      <s-text
        weight="medium"
        size="md-m"
      >
        {{ title }}
      </s-text>
    </div>

    <div class="d-flex content">
      <v-progress-circular
        v-if="isWorking"
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
      <distribution-analytics
        v-else
        v-bind="$attrs"
        :chart-data="statusDistribution"
        :storeKey="key"
        class="mt-10"
        @click="(data) => { $emit('click', data) }"
      />
    </div>
  </s-card>
</template>

<script>
import DistributionAnalytics from '@/components/cards/distribution-analytics/DistributionAnalytics'
import { DistributionCardShellMixin } from '@/components/cards/distribution-analytics/distribution-analytics-mixin.js'

export default {
  name: 'DistributionCard',
  mixins: [DistributionCardShellMixin],
  components: {
    'distribution-analytics': DistributionAnalytics,
  },
}
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 290px;
}
</style>
