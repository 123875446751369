import { mapState } from 'vuex'
import { colours, coloursByTelco, coloursByCountry } from '@/utils/colours'
import { removeSpecialChars } from '@/utils/fmt'

const DistributionAnalyticsMixin = {
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
    },
    linked: {
      type: Boolean,
      required: false,
      default: false,
    },
    chartColors: {
      type: Array,
      required: false,
      default: () => [],
    },
    showDefaultLegend: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      color: this.chartColors.length === 0
        ? colours
        : this.chartColors,
    }
  },
  computed: {
    orderedList: function () {
      if (!this.chartData) return null

      const tempChartData = this.chartData
      const total = tempChartData.reduce(
        (acc, data) => acc + data.count,
        0
      )

      const dataset = []
      // process chart
      tempChartData.forEach((data, i) => {
        // for unknown
        let unknownCount = 0

        const _legend = !data.label
          ? 'Unknown'
          : data.label

        // check if we already have unknown
        const unknownIndex = dataset.findIndex(data => data.legend === 'Unknown')
        if (_legend === 'Unknown' && unknownIndex !== -1) {
          const unknownDataset = dataset[unknownIndex]
          dataset.splice(unknownIndex, 1)
          unknownCount = parseInt(unknownDataset.data)
        }

        dataset.push({
          legend: _legend,
          data: (data.count + unknownCount).toFixed(2),
          color: this.getContextColour(_legend, i),
          key: _legend,
          percent: (total > 0)
            ? (((data.count + unknownCount) / total) * 100).toFixed(1)
            : 0.0,
        })
      })

      const result = dataset
        .sort((a, b) => b.data - a.data)
        .slice(0, 10)
        .map((d, i) => {
          return {
            ...d,
            color: d.color,
          }
        })

      // if the sum of percentage is less than 1, return an empty array
      return result.reduce((total, each) => each.percent + total, 0) < 1
        ? []
        : result
    },
    computedChartData: function () {
      const computedChartData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      }

      this.orderedList.forEach(data => {
        computedChartData.labels.push(data.legend)
        computedChartData.datasets[0].data.push(data.data)
        computedChartData.datasets[0].backgroundColor.push(data.color)
      })
      return computedChartData
    },
  },
  methods: {
    drillDetails (data) {
      this.$emit('click', data)
    },
    getContextColour: function (key, index) {
      if (coloursByTelco[key.toLowerCase()]) {
        return coloursByTelco[key.toLowerCase()]
      }

      if (coloursByCountry[key.toLowerCase()]) {
        return coloursByCountry[key.toLowerCase()]
      }

      return this.color()[index]
    },
  },
}

const DistributionCardMixin = {
  props: {
    title: {
      type: String,
      required: true,
    },
    _key: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      isWorking: false,
    }
  },
  computed: {
    ...mapState({
      chartData (state, getters) {
        return getters[`analytic/distributionMetricsBy${this._key}`]
      },
    }),
    key: function () {
      return removeSpecialChars(this.$attrs.type)
    },
    statusDistribution: function () {
      return this.chartData
    },
  },
  methods: {
    getDistribution () {
      this.isWorking = true
      let method = 'getDistributionMetrics'
      if (this._key.indexOf('Zerodata') !== -1) {
        method = 'getZerodataDistributionMetrics'
      }
      this.$store
        .dispatch(`analytic/${method}`, {
          key: this._key.toLowerCase(),
          params: this.params,
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    expand () {
      this.$emit('expand', { title: this.title, getter: `analytic/distributionMetricsBy${this._key}` })
    },
    refresh () {
      this.getDistribution()
    },
  },
  mounted () {
    this.getDistribution()
  },
}

const DistributionCardShellMixin = {
  props: {
    title: {
      type: String,
      required: true,
    },
    _key: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      isWorking: false,
    }
  },
  computed: {
    statusDistribution: function () {
      return this.chartData
    },
  },
  methods: {
    expand () {
      this.$emit('expand', { title: this.title, getter: `analytic/distributionMetricsBy${this._key}` })
    },
  },
}

export {
  DistributionAnalyticsMixin,
  DistributionCardMixin,
  DistributionCardShellMixin,
}
