<template>
  <div>
    <loader
      v-if="isLoading"
      message="Fetching data ...."
    />
    <div v-else>
      <v-container>
        <s-tabs>
          <v-tab
            v-for="t in timestamps"
            :key="t"
            @click="() => { selectedTimestamp = t }"
          >
            {{ getHumanReadableDateShorter(t) }}
          </v-tab>
        </s-tabs>
        <v-row no-gutters class="mb-8 pt-4" v-if="deliverability">
          <v-col cols="6" class="pa-0 pr-4">
            <s-card class="stats-card pa-4 pb-2 overflow-hidden">
              <div class="d-flex justify-space-between align-center">
                <s-text weight="bold" size="md-m" class="d-flex">
                  Average Deliverability Delay
                </s-text>
              </div>
              <s-text weight="bold" size="m-lg" color="primary">
                <ICountUp
                  :delay="countup.delay"
                  :options="countup.options"
                  :endVal="deliverability.avg_msg_delivery_delay"
                />
              </s-text>
            </s-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col cols="6" class="pa-0 pr-4">
            <distribution-card-shell
              title="msg_envelope_type_aggregation"
              _key="text"
              :chartData="getAggregationData('msg_envelope_type_aggregation', 'type')"
              @expand="showListDialog"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-4">
            <distribution-card-shell
              title="msg_e2ee_type_aggregation"
              _key="text"
              :chartData="getAggregationData('msg_e2ee_type_aggregation', 'type')"
              @expand="showListDialog"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col cols="6" class="pa-0 pr-4">
            <distribution-card-shell
              title="msg_delivery_aggregation"
              _key="text"
              :chartData="getAggregationData('msg_delivery_aggregation', 'delivered')"
              @expand="showListDialog"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-4">
            <distribution-card-shell
              title="msg_block_aggregation"
              _key="text"
              :chartData="getAggregationData('msg_block_aggregation', 'blocked')"
              @expand="showListDialog"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-3">
          <v-col class="d-flex flex-column" cols="5">
            <s-text weight="medium" color="gray" size="sm">
              From
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column" cols="5">
            <s-text weight="medium" color="gray" size="sm">
              To
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column" cols="2">
            <s-text weight="medium" color="gray" size="sm">
              Count
            </s-text>
          </v-col>
        </v-row>
        <v-row
          v-for="m in deliverability.to_from_pairs"
          :key="`${m.from}-${m.to}`"
          class="pa-0 ma-0 mb-3 app-card app-card-content"
        >
          <v-col class="d-flex flex-column" cols="5">
            <s-link
              weight="medium"
              color="grayTextAlt"
              :to="{ name: 'appDetails', params: { id: m.from } }"
            >
              {{ m.from }}
            </s-link>
          </v-col>
          <v-col class="d-flex flex-column" cols="5">
            <s-link
              weight="medium"
              color="grayTextAlt"
              :to="{ name: 'appDetails', params: { id: m.to } }"
            >
              {{ m.to }}
            </s-link>
          </v-col>
          <v-col class="d-flex flex-column" cols="2">
            <div>
              {{ m.count }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import DistributionCardShell from '@/components/cards/distribution-analytics/DistributionCardShell'
import { getHumanReadableDateShorter } from '@/utils/date'
import ICountUp from 'vue-countup-v2'
export default {
  name: 'Deliverability',
  components: {
    loader: Loader,
    ICountUp,
    'distribution-card-shell': DistributionCardShell,
  },
  computed: {
    ...mapGetters({
      timestamps: 'analytic/messageDeliverabilityTimestamps',
      messageDeliverabilityForTimestamp: 'analytic/messageDeliverabilityForTimestamp',
    }),
    deliverability () {
      return this.messageDeliverabilityForTimestamp(this.selectedTimestamp)
    },
  },
  data () {
    return {
      isLoading: false,
      selectedTimestamp: 0,
      countup: {
        delay: 500,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
        },
      },
    }
  },
  methods: {
    getHumanReadableDateShorter,
    getAggregationData (key, label) {
      if (!this.deliverability) return []
      const chartData = this
        .deliverability[key]
        .map(d => {
          return {
            label: String(d[label]),
            count: d.count,
          }
        })
      return chartData
    },
    async getDeliverabilityData () {
      this.isLoading = true
      await this.$store.dispatch('analytic/getMessageDeliverability', { series: 'message_stats' })
      this.isLoading = false
    },
  },
  watch: {
    timestamps: {
      handler () {
        if (this.timestamps) return
        this.getDeliverabilityData()
      },
      immediate: true,
    },
  },
}
</script>
