<template>
  <section class="d-flex ml-auto mr-auto">
    <empty-state
      v-if="chartData && orderedList.length === 0"
      message="No data available"
      hint="Please check back."
      style="height: 350px"
      class="ma-auto"
    />
    <div v-if="chartData && orderedList.length > 0">
      <pie-chart
        :chart-data="computedChartData"
        style="height: 200px"
      />
      <slot name="legend" :orderedList="orderedList" :drillDetails="drillDetails" />
      <ul
        class="pie__legends mt-8 pl-0"
        v-if="showDefaultLegend"
      >
        <li
          class="legend-holder"
          v-for="data in orderedList"
          :key="data.key"
          :ripple="false"
          :class="{
            link: linked,
            'd-flex': true,
            'align-center': true,
          }"
          @click="drillDetails(data)"
        >
          <span class="legend">
            <span class="pie__dot mr-2" :style="{ background: data.color }" />
            <s-text weight="medium" size="sm" class="mr-1 ml-1">
              {{ data.percent }}% -
            </s-text>
            <s-text
              weight="medium"
              size="sm"
              class="mr-1 ml-1"
              :style="{ color: data.color }"
            >
              {{ data.legend }}
            </s-text>
          </span>
        </li>
        <!-- <li
          @click="viewCategory"
          v-if="linked"
          class="d-flex align-center"
        >
          <span class="legend">
            <s-text weight="medium" size="sm" class="mr-1">Show all </s-text>
            <s-icon name="caret-down" color="dark" size="20px" />
          </span>
        </li> -->
      </ul>
    </div>
  </section>
</template>

<script>
import PieChart from '@/components/cards/distribution-analytics/PieChart'
import EmptyState from '@/components/cards/EmptyState'
import { DistributionAnalyticsMixin } from '@/components/cards/distribution-analytics/distribution-analytics-mixin'

export default {
  name: 'DistributionAnalytics',
  mixins: [DistributionAnalyticsMixin],
  components: {
    'pie-chart': PieChart,
    'empty-state': EmptyState,
  },
}
</script>

<style lang="scss" scoped>
.pie__legends {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  list-style: none;

  li {
    margin: 10px 0;
    font-weight: 600;
    text-decoration: none !important;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &,
    & * {
      cursor: pointer;
    }
  }
}
.pie__dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.legend {
  border-radius: 30px;
  padding: 0 6px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--v-grayBg-base);
  }
}
</style>
